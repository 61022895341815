import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { EmployeeService } from '../services/employee.service';
import { AuthService } from '../auth/services/auth.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  permissions: string[] = [];
  currentDate: string = '';
  userData: any = null;
  fullname: string = '';
  // endContract: Date | null = null;
  createdLastPaymentSlip: Date | null | string = null;
  notificationCount: number = 0;
  notifications: any[] = [];
  isMenuOpen: boolean = false;
  isMenuCollapsed: boolean = false;
  isMobile: boolean = window.innerWidth <= 768;
  showLogoutButton: boolean = false;

  @ViewChild('op') op!: OverlayPanel;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = window.innerWidth <= 768;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (this.isMobile && this.isMenuOpen) {
      const isClickInsideMenu = target.closest('.sidebar nav') !== null;
      const isClickOnToggle = target.closest('.menu-toggle') !== null;
      const isClickOnNavOption =
        target.closest('.sidebar nav ul li a') !== null;

      if ((!isClickInsideMenu && !isClickOnToggle) || isClickOnNavOption) {
        this.closeMenu();
      }
    }

    if (this.isMobile && this.showLogoutButton) {
      const isClickOnProfileImage =
        target.closest('.profile-section img') !== null;
      const isClickOnLogoutButton =
        target.closest('.logout-button-mobile') !== null;

      if (!isClickOnProfileImage && !isClickOnLogoutButton) {
        this.showLogoutButton = false;
      }
    }
  }

  menuOptions = [
    {
      label: 'Inicio',
      icon: 'pi pi-home',
      route: '/dashboard/home',
      permission: 'VIEW_INICIO_COLLABORATOR',
    },
    {
      label: 'Vacaciones',
      icon: 'pi pi-sun',
      route: '/dashboard/vacation',
      permission: 'VIEW_VACACIONES_COLLABORATOR',
    },
    {
      label: 'Boletas',
      icon: 'pi pi-file',
      route: '/dashboard/payment-slips',
      permission: 'VIEW_BOLETAS_COLLABORATOR',
    },
    // {
    //   label: 'Ticket y Vales',
    //   icon: 'pi pi-ticket',
    //   route: '/dashboard/ticket',
    //   permission: 'VIEW_VACACIONES_COLLABORATOR',
    // },
    {
      label: 'Solicitudes',
      icon: 'pi pi-clock',
      route: '/dashboard/solicitude',
      permission: 'VIEW_BOLETAS_COLLABORATOR',
    },
    {
      label: 'Mis Contratos',
      icon: 'pi pi-folder-open',
      route: '/dashboard/contracts',
      permission: 'VIEW_BOLETAS_COLLABORATOR',
    },
    // {
    //   label: 'Adelanto de sueldo',
    //   icon: 'pi pi-wallet',
    //   route: '/dashboard/salary-advance',
    //   permission: 'VIEW_BOLETAS_COLLABORATOR',
    // },
  ];

  filteredMenuOptions: any[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private employeeService: EmployeeService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.authService.user$.subscribe((user) => {
      this.userData = user;
      console.log('Usuario actualizado: ', this.userData);
      if (this.userData) {
        this.getName();
      } else {
        console.log('employeeId no está definido');
      }
    });

    this.loadPermissions();
    this.filterMenuOptions();

    const date = new Date();

    // Array con los meses en español
    const months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];

    // Construir la fecha en el formato deseado
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Formato: "Diciembre 18 del 2024"
    this.currentDate = `${month} ${day} del ${year}`;
  }

  loadPermissions(): void {
    const storedAccess = localStorage.getItem('access');
    if (storedAccess) {
      const parsedAccess = JSON.parse(storedAccess);
      this.permissions = parsedAccess.permissions || [];
    }
  }

  filterMenuOptions(): void {
    this.filteredMenuOptions = this.menuOptions.filter((option) =>
      this.permissions.includes(option.permission)
    );
  }

  getName(): void {
    if (!this.userData) {
      console.error('Error: userData no está definido');
      return;
    }

    console.log('Llamando a la API con userData:', this.userData);
    this.employeeService.getEmployeeById(this.userData.employeeId).subscribe(
      (employee) => {
        if (employee) {
          this.fullname =
            employee.names.split(' ')[0] +
            ' ' +
            employee.lastNames.split(' ')[0];

          // if (employee.endDate) {
          //   const [day, month, year] = employee.endDate.split('/').map(Number);
          //   this.endContract = new Date(year, month - 1, day);
          // } else {
          //   this.endContract = null;
          // }

          if (employee.createdLastPaymentSlip) {
            const [day, month, year] = employee.createdLastPaymentSlip
              .split('/')
              .map(Number);
            this.createdLastPaymentSlip = new Date(year, month - 1, day);
          } else {
            this.createdLastPaymentSlip = null;
          }

          this.updateNotifications();

          console.log('Nombre completo obtenido:', this.fullname);
          // console.log('Fecha final obtenida:', this.endContract);

          // this.checkContractEndDate();
        } else {
          console.log('No se encontró el empleado');
        }
      },
      (error) => {
        console.error('Error al obtener el empleado:', error);
      }
    );
  }

  // checkContractEndDate(): void {
  //   // if (!this.endContract) {
  //   //   this.updateNotifications();
  //   //   return;
  //   // }

  //   const today = new Date();
  //   // const endDate = new Date(this.endContract);

  //   const timeDifference = endDate.getTime() - today.getTime();
  //   const daysDifference = timeDifference / (1000 * 3600 * 24);

  //   if (daysDifference <= 30 && daysDifference >= 0) {
  //     this.updateNotifications();
  //   } else {
  //     this.updateNotifications();
  //   }
  // }

  toggleNotification(event: Event, button: HTMLElement) {
    this.op.toggle(event, button);

    setTimeout(() => {
      const panelElement = document.querySelector(
        '.p-overlaypanel'
      ) as HTMLElement;
      const button = document.querySelector('.pi-bell')?.parentElement;

      if (panelElement && button) {
        const navbar = document.querySelector('.navbar') as HTMLElement;
        const buttonRect = button.getBoundingClientRect();

        const navbarRect = navbar?.getBoundingClientRect();
        let offsetLeft = navbarRect
          ? buttonRect.left - navbarRect.left
          : buttonRect.left;

        if (this.isMenuCollapsed) {
          offsetLeft -= 180; // Sumar el ancho del sidebar plegado (70px)
        }

        panelElement.style.left = `${offsetLeft - 15}px`;
        panelElement.style.top = `${buttonRect.bottom - 5}px`;
        panelElement.style.transform = `translateX(-50%)`;
      }
    }, 0);
  }

  toggleMenu(): void {
    if (this.isMobile) {
      this.isMenuOpen = !this.isMenuOpen;
    } else {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    }
  }

  toggleLogoutButton(): void {
    this.showLogoutButton = !this.showLogoutButton;
  }

  closeMenu(): void {
    this.isMenuOpen = false;
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  updateNotifications(): void {
    this.notifications = [];

    // if (this.endContract) {
    //   const today = new Date();
    //   const endDate = new Date(this.endContract);
    //   const timeDifference = endDate.getTime() - today.getTime();
    //   const daysDifference = timeDifference / (1000 * 3600 * 24);

    //   if (daysDifference <= 30 && daysDifference >= 0) {
    //     const formattedEndContract = formatDate(
    //       this.endContract,
    //       'dd/MM/yyyy',
    //       'en-US'
    //     );
    //     this.notifications.push({
    //       title: 'Renovar contrato',
    //       message: `Tu contrato está por expirar el <strong>${formattedEndContract}</strong>.`,
    //     });
    //   }
    // }

    if (this.createdLastPaymentSlip && this.createdLastPaymentSlip !== '') {
      const paymentSlipDate = new Date(this.createdLastPaymentSlip);
      if (!isNaN(paymentSlipDate.getTime())) {
        const formattedCreatedLastPaymentSlip = formatDate(
          paymentSlipDate,
          'dd/MM/yyyy',
          'en-US'
        );
        this.notifications.push({
          title: 'Recibo Emitido',
          message: `Tienes un recibo emitido el <strong>${formattedCreatedLastPaymentSlip}</strong>.`,
        });
      }
    }

    this.notificationCount = this.notifications.length;
  }
}
