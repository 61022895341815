<div class="layout-container">
  <!-- Sidebar -->
  <aside
    class="sidebar"
    [ngClass]="{
      collapsed: isMenuCollapsed,
      'collapsed-mobile': isMenuCollapsed && isMobile
    }"
  >
    <button class="menu-toggle" (click)="toggleMenu()">
      <i class="pi pi-bars"></i>
    </button>
    <!-- Perfil -->
    <div class="profile-section">
      <!-- Botón de logout en modo móvil -->
      <div class="logout-div">
        <img src="/assets/images/image-profile.png" alt="" />
        <button
          class="icon-button logout-button-mobile"
          *ngIf="isMobile"
          (click)="logout()"
        >
          <i class="pi pi-sign-out"></i>
        </button>
      </div>
      <h2>{{ fullname | capitalize }}</h2>
      <span class="role-badge">COLABORADOR</span>
    </div>

    <!-- Barra de búsqueda -->
    <div class="search-bar">
      <i class="pi pi-search search-icon"></i>
      <input type="text" placeholder="Buscar" />
    </div>

    <!-- Navegación -->
    <nav [ngClass]="{ show: isMenuOpen }">
      <ul>
        <li *ngFor="let option of filteredMenuOptions">
          <a [routerLink]="option.route">
            <i [class]="option.icon + ' icon'"></i>
            <span>{{ option.label }}</span>
          </a>
        </li>
      </ul>
    </nav>
  </aside>

  <!-- Main content -->
  <div class="main-content">
    <!-- Navbar -->
    <header class="navbar">
      <div class="navbar-right">
        <!-- Fecha -->
        <span class="current-date">{{ currentDate }}</span>
        <!-- Iconos -->
        <button class="icon-button">
          <i class="pi pi-envelope"></i>
        </button>
        <button
          #notificationButton
          class="icon-button"
          (click)="toggleNotification($event, notificationButton)"
        >
          <i class="pi pi-bell"></i>
          <p-badge
            *ngIf="notificationCount > 0"
            [value]="notificationCount"
            severity="danger"
          ></p-badge>
        </button>
        <button class="icon-button">
          <i class="pi pi-cog"></i>
        </button>
        <button
          class="icon-button logout-button-web"
          *ngIf="!isMobile"
          (click)="logout()"
        >
          <i class="pi pi-sign-out"></i>
        </button>
      </div>
    </header>

    <p-overlayPanel #op [showCloseIcon]="true" [dismissable]="true">
      <div *ngIf="notifications.length > 0">
        <div
          class="notification-content"
          *ngFor="let notification of notifications"
        >
          <div class="icon-container">
            <i class="pi pi-info-circle"></i>
          </div>
          <div class="text-content">
            <h4>{{ notification.title }}</h4>
            <p [innerHTML]="notification.message"></p>
          </div>
        </div>
      </div>
      <div *ngIf="notifications.length === 0">
        <div class="notification-content">
          <p>No hay notificaciones.</p>
        </div>
      </div>
    </p-overlayPanel>

    <!-- Área de contenido dinámico -->
    <section>
      <router-outlet></router-outlet>
    </section>
  </div>
</div>
