import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
  ],
  templateUrl: './login-collaborator.component.html',
  styleUrls: ['./login-collaborator.component.scss'],
})
export class LoginCollaboratorComponent {
  loginForm: FormGroup;
  errorMessage: string | null = null;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rolType: ['COLLABORATOR', Validators.required],
    });
  }

  onLogin() {
    if (this.loginForm.valid) {
      const { username, password, rolType } = this.loginForm.value;

      this.authService.login(username, password, rolType).subscribe({
        next: (response) => {
          console.log('Login exitoso:', response);
          this.authService.saveToken(response.token);
          alert('Inicio de sesión exitoso');
          this.router.navigate(['/dashboard']); // Cambia a la ruta correspondiente
        },
        error: (error) => {
          console.error('Error al iniciar sesión:', error);
          this.errorMessage = 'Credenciales incorrectas';
        },
      });
    } else {
      console.log('Formulario inválido');
    }
  }
}
