import { Routes } from '@angular/router';
import { LoginCollaboratorComponent } from './auth/components/collaborator/login-collaborator/login-collaborator';

export const routes: Routes = [
  {
    path: '**',
    redirectTo: 'auth/collaborator-login',
  },
  { path: 'auth/collaborator-login', component: LoginCollaboratorComponent },
  // Puedes agregar más rutas aquí.
];