<div class="login-page">
  <div class="login-header">
    <div class="logos-multi">
      <img src="logo1.png" alt="Logo KeOla" class="logo" />
      <img src="logo2.png" alt="Logo IncluB" class="logo" />
      <img src="logo3.png" alt="Logo InResorts" class="logo" />
    </div>
    <div class="logo-single">
      <img src="logo4.png" alt="Logo Language" class="logo" />
    </div>
  </div>

  <div class="login-box">
    <mat-icon class="user-icon">person</mat-icon>
    <h1 class="login-heading">Iniciar sesión</h1>
    <p class="login-description">Inicia sesión y administra tus solicitudes como colaborador</p>

    <form [formGroup]="loginForm" class="login-form" (ngSubmit)="onLogin()">
      <mat-form-field appearance="outline" class="input-field">
        <mat-label>Usuario</mat-label>
        <input matInput id="username" formControlName="username" placeholder="Ingrese su usuario" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="input-field">
        <mat-label>Contraseña</mat-label>
        <input matInput id="password" formControlName="password" type="password" placeholder="Ingrese su contraseña" />
      </mat-form-field>

      <div class="form-actions">
        <div>
          <input type="checkbox">
          <a>Recordarme</a>
        </div>
        <a class="forgot-password" href="#">Olvidé mi contraseña</a>
      </div>

      <button mat-raised-button style="background-color: #ffa600; color: white;" class="submit-button">
        INGRESAR
      </button>
    </form>
  </div>
</div>

<footer class="page-footer">
  <div class="footer-arc">
    <p>©2024 Derechos reservados</p>
  </div>
</footer>