import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { BadgeModule } from 'primeng/badge';
import { OverlayModule } from 'primeng/overlay';
import { OverlayPanelModule } from 'primeng/overlaypanel';


@NgModule({
	declarations: [
		LayoutComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		BadgeModule,
		OverlayModule,
		OverlayPanelModule,
		SharedModule
	]
})
export class LayoutModule {}
